import React from "react"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Section from "../../components/Section"
import SectionTitle from "../../components/SectionTitle"

const NotFoundPage: React.FC = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Section isWhite={true} addClass="lineup_sec">
      <SectionTitle
        enText={"404: Not Found"}
        jaText={"ページが見つかりませんでした。"}
      />
    </Section>
  </Layout>
)

export default NotFoundPage
